import React from "react";

import './service-copy-list.style.scss';

const ServiceCopyList = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/2">

      </div>
      <div className="w-full md:w-1/2">
        
      </div>
    </div>
  );
}


export default ServiceCopyList;

import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import SubHero from '../components/elements/sub-hero/sub-hero';
import ListItem from '../components/elements/list-item/list-item';
import TwoColFullScreen from '../components/layouts/2-col-full-screen/2-col-full-screen';
import ServiceInfo from '../components/services/service-info/service-info';
import ServiceCopyList from '../components/services/services-copy-list/services-copy-list';
import ServiceBox from '../components/services/service-box'
import BookCta from '../components/elements/book-cta/book-cta';
import Testimonials from '../components/elements/testimonials/testimonials';
import InstaFeed from '../components/elements/insta-feed/insta-feed';

const AestheticsPage = ({ data }) => {

  let [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout pageType='aesthetics'>
      <SEO
        keywords={[`Aesthetics Ely`, `Aesthetics Salon Ely`, `Aesthetics Treatments Ely`]}
        title="Aesthetics"
      />
      <SubHero 
        loaded={loaded}
        bgImage={data.herofull.childImageSharp.fluid}
        data={{
          title: 'Aesthetics',
          subTitle: 'Consultations are discreet and necessary for all treatments before booking. Deposits of 50% required for new clients.',
          fourCol: {
            title: <div>OUR <br/> TREATMENTS</div>,
            textList: [
              <div key={1}><ListItem text="PROFHILO ™" /><ListItem text="Underarm Hyperhidrosis Treatment for unwanted sweating" /><ListItem text="Lumecca" /><ListItem text="PRP Therapy" /></div>,
              <div key={2}><ListItem text="Dermal Filler Treatment 1ml (various areas)" /><ListItem text="Anti Wrinkle Injections"/><ListItem text="Morpheus8" /><ListItem text="Carboxytherapy" /></div>,
              <div key={3}><ListItem text="Tear Trough Under Eye Treatment" /><ListItem text="B12 Injections" /><ListItem text="Microneedling" /><ListItem text="Forma" /><ListItem text="Microsclerotherapy" /></div>
            ]
          }
        }}
      />
      {loaded && <div>
      <TwoColFullScreen cols={[{
          bg: 'text-white bg-primary-darker',
          content: <ServiceInfo 
            key={1}
            title="PROFHILO ™"
            priceList={[
              {
                price: '£555',
                text: 'Dual Treatment',
                padding: 'pr-6'
              },
              {
                price: '£330',
                text: 'Single Treatment',
                padding: 'pr-6'
              }
            ]}
            alignPrice="center"
            upperText={<p>Known as the &apos;5 point Facelift&apos; this skin laxity treatment, Profhilo, remodels skin to tighten and lift as well as restore radiance from the deepest layers of your skin. Profhilo acts like a hydration elixir under the skin, delivering potent results as a stand-alone treatment or in combination with other aesthetic treatment regimes (such as Botox®). 
              <br/> <br/>
              Initial treatments are recommended in a set of two, each four weeks apart, and top ups can be given as required, est.12 months apart.
              <br/> <br/>
              NB- Single doses are available if preferred, but recommended as a booster rather than a stand-alone treatment. This is at your discretion.
              <br/> <br/>
              Suitable for all areas including hands.</p>
            }
          />
        },
        {
          bg: 'bg-white',
          content: <ServiceInfo 
            key={2}
            title={<div>Dermal Filler <br/>Treatment 1ml</div>}
            priceList={[
              {
                price: '£405',
                text: 'per ml',
                padding: 'pr-6'
              },
              {
                price: '£205',
                text: 'per 0.5 ml',
              }
            ]}
            alignPrice="center"
            upperText={<p>Enhancing natures best work with a little subtle help.<br/><br/>
                We prefer to work with your natural curves and contours, but will happily work with you to give you the results you desire with the highest-grade reputable products.<br/> <br/>
                Nothing too drastic is taken on in any one appointment to ensure you heal adequately, and love the look overall.<br/><br/>
                Maybe you want a subtle smoothing of laughter lines, or a perfect plump pout. Whatever your choice, we are here to give you the treatment of your dreams.
              </p>
            }
            list={{
              title: 'Suitable for:',
              items: [
                'Lip Enhancement aka ‘The Soft fill Lip',
                'Nasolabial Folds (outer nose to outer lip)',
                'Marionette Lines (outer mouth to chin lines)',
                'Cheeks'
              ]
            }}
          />
        },
        {
          bg: 'bg-primary-darker text-white md:text-black md:bg-white',
          content: <ServiceInfo 
            key={3}
            title={<div>Tear Trough Under Eye Treatment </div>
            }
            priceList={[
              {
                price: '£325',
              },
            ]}
            alignPrice="top"
            upperText={<p>Tear trough filler treatment quickly and effectively restores lost volume and reduces dark circles under the eyes. It also reduces fine lines and wrinkles, as the dermal filler will boost collagen production by attracting hydration for the months to come.</p>}
          />
        },
        {
          bg: 'bg-white md:text-white md:bg-primary-darker',
          content: <ServiceInfo 
            key={4}
            title={<div>Anti Wrinkle Injections</div>
            }
            priceList={[
              {
                price: '£225',
                text: '1 Area',
                padding: 'pr-6'
              },
              {
                price: '£285',
                text: '2 Areas',
                padding: 'pr-6'
              },
              {
                price: '£325',
                text: '3 Areas',
              },
              {
                price: 'From £500',
                text: 'Hyperhidrosis treatment',
              },
            ]}
            alignPrice="bottom"
            upperText={<p>Strictly by consultation only and available in multiple areas.</p>}
          />
        },
        {
          bg: 'text-white bg-primary-darker',
          content: <div key={5}>
            <ServiceInfo 
              title={<div>&apos;No Glow&apos; Underarm 
                Hyperhidrosis Treatment 
                for unwanted sweating</div>
              }
              priceList={[
                {
                  price: '£500',
                },
              ]}
              alignPrice="top"
              upperText={<p>Treats hyperhidrosis (excessive sweating). This treatment is for both men and women and will last for up to six months. Use this treatment for a specific occasion such as a wedding or simply to prevent excess sweating. 
              </p>}
            />
           <span className="block h-10"></span>
            <ServiceInfo 
              title={<div>Consultations</div>}
              priceList={[
                {
                  price: '£35',
                },
              ]}
              alignPrice="top"
              upperText={<p>
                Unsure about what to book? Need to talk through your in-depth treatment? 15-minute consultations are available to chat in person privately.
                <br/><br/><b>*Non refundable booking fee of £35 needed to secure all appointments for new clients.</b>
              </p>}
            />
          </div>
        },
        {
          bg: 'bg-white',
          content: <div key={6}>
            <ServiceInfo 
            title={<div>B12 Injections</div> }
            priceList={[
              {
                price: '£295',
                text: 'Course of 10',
                padding: 'pr-6'
              },
              {
                price: '£35',
                text: 'Single dose',
              }
            ]}
            alignPrice="center"
            upperText={<p>B12 is being commonly used as a pick me up for times of over-work, stress, and simply in conjunction with a tiring lifestyle. As always, we recommend you speak to your doctor if you are concerned about an on-going deficiency.</p>}
            list={{
              title: 'BENEFITS of B12 include:',
              items: [
                'Reduces fatigue',
                'Increases energy levels',
                'Improves physical Performance',
                'Helps with blood cell formation',
                'Prevents anaemia',
                'Supports healthy hair, skin and nails',
                'Reduces risk of macular degeneration',
                'Even aids weight loss',
                'Increases brain function',
                'Supports bone health and prevents osteoporosis',
              ]
            }}
            lowerText={<p>Booking will be at an agreed schedule based upon your medical needs. The sessions are flexible and can be booked at your discretion up to a schedule of every three days, but are usually weekly or bi-weekly.</p>}
          />
          </div>
        }, 
        {
          bg: 'bg-white',
          content: <div key={5}>
            <ServiceInfo 
              title={<div>Morpheus8 </div>}
              priceList={[
                {
                  price: '£850',
                  text: 'Face'
                },
                {
                  price: '£995',
                  text: 'Face and Neck'
                },
                {
                  price: '£995',
                  text: 'Body area ie abdomen'
                },
              ]}
              alignPrice="bottom"
              upperText={<span>per session</span>}
              lowerText={<p>
              Believe the hype. Morpheus8 is a game changing treatment for those that want serious results without serious downtime. Using RF energy and micro needling to remodel and contour the face, as well as improve skin texture for a more youthful glow. 
              <br/><br/>
              Morpheus8 can be used all over the body to lift the entire face, remodel imperfections, erase stretch marks, treat acne scars and resurface the skin. As seen in worldwide press coverage and on many celebrities, this treatment is a first in Ely and the surrounding areas.
              </p>}
            />
           <span className="block h-10"></span>
            <ServiceInfo 
              title={<div>Package of all 3</div>}
              priceList={[
                {
                  price: '£2700',
                },
              
              ]}
              alignPrice="center"
              upperText={
                <div>
                <b>Including:</b>
                <ul>
                  <li>Germaine Du Cappucini Hyaluronic acid (worth £55)</li>

                  <li>3x Forma sessions (worth £597)</li>

                  <li>TimeExpert SRNS Facial (worth £75)</li>
                </ul>
              </div>}
            />
          </div>
        },
        {
          bg: 'text-white bg-primary-darker',
          content: <div key={6}>
          <ServiceInfo 
            title={<div>Forma</div>
            }
            priceList={[
              {
                price: '£150',
                text: 'Face (per session)',
              },
              {
                price: '£825',
                text: 'Course of 6',
              },
              {
                price: '£199',
                text: 'Face and Neck (per session)',
              },
              {
                price: '£1100',
                text: 'Course of 6',
              },
            ]}
            alignPrice="center"
            upperText={<p>
              A celebrity favourite! Forma is an innovative treatment offers a tighter, more youthful skin on the face, neck, and body in a safe, non-invasive manner. Complete rejuvenation therapy for lifting and firming sagging skin without the drawbacks of a surgical procedure and no needles involved! 
            </p>}
          />
          <span className="block h-10"></span>
          <ServiceInfo 
            title={<div>Lumecca</div>
            }
            priceList={[
              {
                price: '£175',
                text: 'per session',
              }
            ]}
            alignPrice="center"
            upperText={<p>
             This IPL therapy is elite in its ability to reduce and remove pigment from all over the body. It is particularly effective in removing age spots, sun damage, spider veins, port wine stains, rosacea, freckles and pigmentation. Visible results from the first one to two sessions. 
            </p>}
          />
          </div>
        },
        {
          bg: 'text-white bg-primary-darker',
          content: <div key={7}>
            <ServiceInfo 
              title={<div>PDO Threads</div> }
              priceList={[
                {
                  price: '£995',
                  text: 'Full Face thread lift',
                  padding: 'pr-6'
                },
                {
                  price: '£595',
                  text: 'Lower Face thread lift ',
                  padding: 'pr-6'
                },
                {
                  price: '£395',
                  text: 'Eye or Brow thread lift',
                  padding: 'pr-6'
                },
                {
                  price: '£35',
                  text: 'Consultations',
                }
              ]}
              alignPrice="center"
              upperText={<p>A Thread lift is minimally invasive rejuvenating treatment which is effective at lifting sagging skin, giving a more youthful appearance.</p>}
              lowerText={<p className="text-xs mb-10">Unsure about what to book? Need to talk through your in-depth treatment options?
                  15-minute consultations are available to chat in person privately.
              </p>}
            />
            <ServiceInfo 
              title={<div>
                Carboxy therapy</div>
              }
              priceList={[
                {
                  price: '£500',
                  text: 'Course of 6 (buy 5 get 1 free)',
                },
                {
                  price: '£105',
                  text: 'Additional sessions',
                },
                {
                  price: '£40',
                  text: 'Consultation',
                },
              ]}
              alignPrice="center"
              list={{
                items: [
                  'Dark circles',
                  'Stretch marks',
                  'Cellulite',
                  'Fine lines and Wrinkles',
                  'Less invasive than other treatments, this can be used all over the body.',
                ]
              }}
              upperText={<p>Carboxytherapy uses infusions of carbon dioxide which are expertly injected under the skin. Carboxytherapy is a non surgical treatment to treat and reduce the appearance of:</p>}
              lowerText={<p>Recommended treatment of 6, up to twice weekly. Top ups recommended quarterly.</p>}
            />       
          </div>
        },
        {
          bg: 'bg-white',
          content: <div key={8}>
          <ServiceInfo 
            title={<div>
              PRP Facial (platelet rich plasma)</div>
            }
            priceList={[
              {
                price: 'Book or call for prices',
              },
            ]}
            alignPrice="center"
            list={{
              title: "PRP is cutting edge for skin that suffers with:",
              items: [
                'Reducing fine lines and wrinkles',
                'Tightening pore',
                'Crow’s feet',
                'Oxygenating sallow skin',
                'Acne scarring',
                'Rosacea'
              ]
            }}
            upperText={<p>Platelet rich plasma (PRP), also known as the Vampire Facial or an ‘Angel Lift’ is a celebrity favourite for good reason. Using your own blood which is safely collected by your practitioner, the blood is put through a centrifuge, and the plasma separated from the red cells whilst you relax and are fully numbed ahead of your facial. This is an excellent alternative to skin boosters for people concerned about foreign chemicals being used in the skin.</p>}
            lowerText={<p>When PRP is placed back into the skin for cosmetic reasons it supercharges the body’s own collagen, replacing tissue that has been damaged or lost through the aging process.

              Medically this treatment has been successfully used for years to accelerate wound healing and heal damaged ligaments, this same protocol is a superfood for the skin’s collagen and elastin cells. 
              
              Within a few weeks of treatment, an overall improvement in skin hydration, texture and tone can be seen. Glowing skin is visible in weeks, and full results within six months. </p>}
          />       
        </div>
        }, 
        {
          bg: 'bg-white',
          content: <div key={9}>
             <ServiceInfo 
              title={<div>Microneedling </div>
              }
              priceList={[
                {
                  price: '£155',
                  text: 'Face',
                },
                {
                  price: '£180',
                  text: 'Face & Neck',
                },
                {
                  price: '£399',
                  text: 'Course of 3 Facial Treatments',
                },
                {
                  price: '£499',
                  text: 'Course of 3 Face & Neck Treatments',
                },
              ]}
              alignPrice="center"
              upperText={<p>
                A minimally invasive cosmetic procedure to stimulate the skins healing process which produces collagen and elastin. 
              </p>}   
            />   
            <span className="block h-10"></span>
            <ServiceInfo 
              title={<div>Scalp PRP for thinning hair</div>
              }
              priceList={[
                {
                  price: '£750',
                  text: 'Course of 3 Scalp PRP',
                },
                {
                  price: '£305',
                  text: 'Single PRP Scalp session',
                },
              ]}
              alignPrice="center"
              upperText={<p>
                This uses the same principles as traditional PRP therapy, and is a proven method for stimulating the hair follicles into new growth.

                Using the same gentle collection process as the PRP Facial treatment this is an excellent treatment for thinning hair for ladies and gentlemen.

                A course is recommended for optimal results
              </p>}   
            />   
          </div>
        },
        {
          bg: 'text-white bg-primary-darker',
          content: <div key={6}>
          <ServiceInfo 
            title={<div>Micro Sclerotherapy for Spider/ Thread Veins</div>
            }
            priceList={[
              {
                price: 'from £305',
                text: 'Consultation needed before treatment',
              },
            ]}
            alignPrice="center"
            upperText={<p>

              Micro Sclerotherapy is a procedure in which a qualified practitioner, using a syringe with a tiny needle, injects a small amount of a special solution directly into the thread vein. The solution displaces the blood within the vein, making it blanch or turn white. To provide best results proper aftercare must be adhered to.
            </p>}
            lowerText={<p>
             <b>POST TREATMENT:</b> To achieve optimum results you will be sent home with a compression waterproof dressing on the area. The treated thread and spider veins need to be continuously compressed for 21 days and nights. This is the time needed for the body to turn the dying vein into scar tissue and is best achieved by wearing continuous compression. If they are removed too early, blood will return into the vein leaving a brown staining under the skin. This staining can fade over the following months but sometimes it can become permanent if aftercare is not followed.
            </p>}
          />
          </div>
        }
      ]}/>
      <ServiceCopyList />
      <div className="flex flex-wrap">
        {[
          {
            title: 'Permanent Make Up',
            text: 'Discover',
            image: data.permanent.childImageSharp.fluid,
            link: '/aesthetics/permanent-makeup'
          },
          {
            title: 'Medical Tattooing ',
            text: 'Discover',
            image: data.tattooing.childImageSharp.fluid,
            link: '/aesthetics/medical-tattooing'
          },
        ].map(({title, image, text, link}, i) => (
            <div key={i} className="w-full sm:w-1/2">
              <ServiceBox height='60' service={{title, image, text, link}} />
            </div>
          ))}
      </div>
      <Testimonials />
      <BookCta discount="https://gift-cards.phorest.com/salons/houseofpureltd" link="https://phorest.com/book/salons/houseofpureltd" phone="01353 661 321" email="elybeautyandskinclinic@gmail.com" bg={data.bookbg.childImageSharp.fluid} marginBottom />
      <InstaFeed token="IGQVJWSFBsWDctOXpzeVo4S1VNYjJOc0hHbnY4OHR4ZAUZAPc193c3pJRGY1M2NNVS16RnpRaHpJeEF3Yy1HRjN4OVQ2QndlZAzdhWFlsYkhoYTVOcmZA4cTYyMzFNOVBtdnBmX0VEbE5yVHFabkNka1VDdAZDZD" />
      </div>}
    </Layout>
  );
}

AestheticsPage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    herofull: file(relativePath: { eq: "aesthetics-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tattooing: file(relativePath: { eq: "medical-tattoing-cta.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    permanent: file(relativePath: { eq: "permanent-makeup-bg-cta.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const AestheticsComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AestheticsPage props data={data} {...props} />
    )}
  />
)

AestheticsComponent.displayName = "AestheticsComponent"

export default AestheticsComponent
